/** @jsx jsx */
import { PageLayout } from '../components/Layout';
import { navigate } from 'gatsby';
import { ArticleItem } from '../components/ArticleItem';
import { Sidebar } from '../components/Sidebar';
import { jsx } from 'theme-ui';
import { Button } from '../components/Button';
import _ from 'lodash';
import { SEO } from '../components/SEO';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
interface Props {
  data: any;
  pageContext: any;
}
const TagPage = ({ pageContext }: Props) => {
  const { BeforeArticlesList } = pageContext;

  return (
    <PageLayout>
      {BeforeArticlesList[0].enabled && (
        <div
          sx={{
            margin: 'auto',
            maxWidth: '1060px',
            padding: '0 10px',
            marginBottom: '30px',
          }}
        >
          <MDXProvider components={{}}>
            <MDXRenderer>{BeforeArticlesList[0].body}</MDXRenderer>
          </MDXProvider>
        </div>
      )}
      <SEO title={pageContext.tag} />
      <h1 sx={{ marginBottom: '50px' }}>{pageContext.tag}</h1>
      <div
        sx={{
          display: 'grid',
          gap: '30px',
          padding: '0 10px',
          gridTemplateColumns: ['1fr', '2fr 1fr'],
        }}
      >
        <div>
          {pageContext.articles.map((article: any) => {
            return (
              <ArticleItem
                basePath={pageContext.basePath}
                key={article.id}
                article={article}
              ></ArticleItem>
            );
          })}
          <div sx={{ display: 'flex', justifyContent: 'center' }}>
            {pageContext.currentPage > 1 && (
              <Button
                sx={{ marginRight: '5px' }}
                onClick={() => {
                  navigate(
                    pageContext.currentPage === 2
                      ? `/tag/${_.kebabCase(pageContext.tag)}`
                      : `/tag/${_.kebabCase(pageContext.tag)}/${
                          pageContext.currentPage - 1
                        }`
                  );
                }}
              >
                Previous Page
              </Button>
            )}
            {pageContext.currentPage !== pageContext.numPages && (
              <Button
                sx={{ marginLeft: '5px' }}
                onClick={() =>
                  navigate(
                    `/tag/${_.kebabCase(pageContext.tag)}/${
                      pageContext.currentPage + 1
                    }`
                  )
                }
              >
                Next Page
              </Button>
            )}
          </div>
        </div>
        <Sidebar />
      </div>
    </PageLayout>
  );
};

export default TagPage;
